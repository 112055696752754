import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "@mui/material";
import axios from "axios";
import { tokens } from "../theme";
import { useSelector } from "react-redux";
import { API_URL } from "../utils";

const BarChart = ({ isDashboard = false }) => {
  const [barData, setBarData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { org_id, org_name } = useSelector(state => state.auth);

  useEffect(() => {
    console.log("ORG ID IS ", org_id)
    fetchData();
  }, [org_id]);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/organisations/dashboard/data/${org_id}/`
      );
      setBarData(formatBarData(res.data));
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const formatBarData = (data) => {
    const { nft_buy_history_data, nft_sell_history_data } = data;

    const buyPrimaryData = nft_buy_history_data
      .filter((item) => item.state === "Primary Market")
      .map((item) => ({
        id: item.id,
        state: "Primary Market Buy",
        price: item.price,
      }));

    const sellPrimaryData = nft_sell_history_data
      .filter((item) => item.state === "Primary Market")
      .map((item) => ({
        id: item.id,
        state: "Primary Market Sell",
        price: item.price,
      }));

    const buySecondaryData = nft_buy_history_data
      .filter((item) => item.state === "Secondary Market")
      .map((item) => ({
        id: item.id,
        state: "Secondary Market Buy",
        price: item.price,
      }));

    const sellSecondaryData = nft_sell_history_data
      .filter((item) => item.state === "Secondary Market")
      .map((item) => ({
        id: item.id,
        state: "Secondary Market Sell",
        price: item.price,
      }));

    return [...buyPrimaryData, ...sellPrimaryData, ...buySecondaryData, ...sellSecondaryData];
  };

  return (
    <ResponsiveBar
      data={barData}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.gray[100],
            },
          },
          legend: {
            text: {
              fill: colors.gray[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.gray[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.gray[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.gray[100],
          },
        },
      }}
      keys={["price"]}
      indexBy="id"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear", min: 5000 }}
      indexScale={{ type: "band", round: true }}
      colors="#8B4513"
      borderColor={{
        from: "color",
        modifiers: [["darker", "1.6"]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "Transaction ID",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "Price",
        legendPosition: "middle",
        legendOffset: -40,
        tickValues: [50000, 200000, 400000, 800000, 1000000, 1500000, 2000000], // Starting at 5000
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in state: " + e.indexValue;
      }}
    />
  );
};

export default BarChart;
