import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Grid } from '@mui/material'
import { API_URL, CONFIG } from '../utils'
import PrimaryItem from '../components/PrimaryItem/PrimaryItem';
import Loading from '../components/Loading';


const PrimaryMarket = () => {

    const [nfts, setNfts] = useState([]);

    const [loader, set_loader] = useState(false)

    const get_nft = async (req, res) => {

        try {
            set_loader(true)
            const { data } = await axios.get(`${API_URL}/nfts/primary-market/`)
            set_loader(false)
            setNfts(data);
        } catch (error) {
            set_loader(false)
            console.log(error.message);
        }

    }
    useEffect(() => {
        get_nft()
    }, [])


    return (
        <div className="py-5 bg page">
            <div className='container px-3 px-lg-0 px-md-0 px-sm-0' >
                {
                    loader ? <Loading /> : <Grid container spacing={4}>
                        {
                            nfts && nfts.map(nft => {
                                return <PrimaryItem key={nft.id} nft={nft} get_nft={get_nft} />
                            })
                        }
                    </Grid>
                }

            </div>
        </div>
    )
}

export default PrimaryMarket
