let PRODUCTION = 'production'
let LOCAL = 'dev'

let MODE = LOCAL

let API_URL = '', APP_URL = ''

const LOCAL_URL = 'https://api.dcarbonxdemo.com/'
const PRODUCTION_URL = 'https://api-dcarbonx.onrender.com'

const APP_URL_LOCAL = 'https://dcarbonxdemo.com'
const APP_URL_PRODUCTION = 'https://dcarbonx.onrender.com'


if (MODE === PRODUCTION) {
    API_URL = PRODUCTION_URL
    APP_URL = APP_URL_PRODUCTION
} else {
    API_URL = LOCAL_URL
    APP_URL = APP_URL_LOCAL
}

export { APP_URL, API_URL }

export const CONFIG = {
    headers: { 'Content-Type': 'application/json', }
}