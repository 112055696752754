import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { 
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
    Label,
    FormGroup
} from 'reactstrap';
import { API_URL } from '../../utils';


const FormPartThree = ({ accounts, connectWallet, formData, setFormData }) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const [dropdownOpen1, setDropdownOpen1] = useState(false);
    const toggle1 = () => setDropdownOpen1((prev) => !prev);
    
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const toggle2 = () => setDropdownOpen2((prev) => !prev);

    const [dropdownOpen3, setDropdownOpen3] = useState(false);
    const toggle3 = () => setDropdownOpen3((prev) => !prev);

    const [orgs, setOrgs] = useState([])
    const [organisation, setOrganisation] = useState({})
    const [project, setProject] = useState({})
    const options = [{ id: 1, value: "tCO2eq"}, { id: 2, value: "MtC02eq"}, {id: 3, value: "GtC02eq"}]
    const options_total = [
        { id: 1, value: 1}, 
        { id: 2, value: 10}, 
        { id: 3, value: 100}, 
        {id: 4, value: 1000},
        {id: 5, value: 10000}
    ]
    const [dateError, setDateError] = useState('')

    useEffect(() => {
        getCarbonOffsets();
    }, [])

    const getCarbonOffsets = () => {
        axios.get(`${API_URL}/organisations/projects/`)
        .then(res => {
            // console.log('orgs_projects: ', res.data);
            setOrgs(res.data)
        })
    }
    

    return (
        <div>
            <FormGroup>
                <Label>
                    Asset Name
                </Label>
                <Input
                    name="assetName"
                    type="text"
                    required
                    value={formData.assetName}
                    onChange={(e) => {
                        setFormData({ ...formData, assetName: e.target.value });
                    }}
                />
            </FormGroup>

            {/* <FormGroup>
                <Label>
                    Total
                </Label>
                <Input
                    name="total"
                    type="number"
                    required
                    min={1}
                    value={formData.total}
                    onChange={(e) => {
                        setFormData({ ...formData, total: e.target.value, decimals:  Math.log10(e.target.value)});
                    }}
                />
            </FormGroup> */}

            <Dropdown isOpen={dropdownOpen2} toggle={toggle2}>
                <DropdownToggle className="w-100 mb-2"
                style={{ textAlign: "left", backgroundColor: '#A0522D' }} caret>
                     {formData.total ? formData.total : "Total"}
                </DropdownToggle>
                <DropdownMenu>
                {options_total.map((option, id) => (
                    <div key={option.id.toString()}>
                        <DropdownItem
                            value={option.value}
                            onClick={(e) => {
                                setFormData({
                                    ...formData,
                                    total: e.target.value,
                                    decimals:  Math.log10(e.target.value)
                                });
                            }}
                        >
                            {option.value}
                        </DropdownItem>
                        <DropdownItem divider />

                        {id !== options_total.length - 1 && <DropdownItem divider />}
                    </div>
                ))}
                </DropdownMenu>
            </Dropdown>

            <FormGroup>
                <Label>
                    Decimals
                </Label>
                <Input
                    name="decimals"
                    type="number"
                    value={formData.decimals}
                    disabled
                    // onChange={(e) => {
                    //     setFormData({ ...formData, decimals: e.target.value });
                    // }}
                />
            </FormGroup>

        </div>
    )
}

export default FormPartThree
