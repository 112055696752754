import React from "react";

import { useTheme } from "@mui/material";
import { ResponsiveChoropleth } from "@nivo/geo";
import { geoFeatures } from "../data/mockGeoFeatures";
import { tokens } from "../theme";
import { useSelector } from "react-redux";
import axios from 'axios';
import { useEffect, useState } from "react";


const GeographyChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { org_country } = useSelector(state => state.auth);
  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      const apiUrl = "https://api.dcarbonxdemo.com/organisations/projects/";
      try {
        const response = await axios.get(apiUrl);
        const countries = response.data;
        const countryDataList = [];

        for (const country of countries) {
          const orgCountryName = country.organisation_country;
          if (orgCountryName) {
            // Get the country code from restcountries API
            const countryCodeUrl = `https://restcountries.com/v3.1/name/${orgCountryName}?fullText=true`;
            const countryResponse = await axios.get(countryCodeUrl);
            if (countryResponse.data.length > 0) {
              const countryCode = countryResponse.data[0].cca3;
              console.log(`countrycode is  ${countryCode}`)
              // Add country data to the list
              countryDataList.push({ id: countryCode, value: 100 });
            }
          }
        }
        console.log(`countryDataList ${countryDataList}`)
        setCountryData(countryDataList);
      } catch (error) {
        console.error("Error fetching countries or country codes:", error);
      }
    };

    fetchCountries();
  }, []);

  return (
    <ResponsiveChoropleth
      data={countryData}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.gray[100],
            },
          },
          legend: {
            text: {
              fill: colors.gray[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.gray[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.gray[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.gray[100],
          },
        },
      }}
      features={geoFeatures.features}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      colors={["#8B4513"]}
      domain={[0, 100]}
      unknownColor="#666666"
      label="properties.name"
      valueFormat=".2s"
      projectionScale={isDashboard ? 40 : 150}
      projectionTranslation={isDashboard ? [0.49, 0.6] : [0.5, 0.5]}
      projectionRotation={[0, 0, 0]}
      borderWidth={1.5}
      borderColor="#ffffff"
      legends={
        !isDashboard
          ? [
              {
                anchor: "bottom-left",
                direction: "column",
                justify: true,
                translateX: 20,
                translateY: -100,
                itemsSpacing: 0,
                itemWidth: 94,
                itemHeight: 18,
                itemDirection: "left-to-right",
                itemTextColor: colors.gray[100],
                itemOpacity: 0.85,
                symbolSize: 18,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#ffffff",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
          : undefined
      }
      tooltip={({ feature }) => (
        <div
          style={{
            background: "#333",
            color: "#fff",
            padding: "5px 10px",
            borderRadius: "4px",
          }}
        >
          {feature.properties.name}
        </div>
      )}
    />
  );
};

export default GeographyChart;
