import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMode } from "../theme";
import axios from "axios";
import {
    Box,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { tokens } from "../theme";
import LineChart from "../components/LineChart";
import BarChart from "../components/BarChart";
import GeographyChart from "../components/GeographyChart";
import StatBox from "../components/StartBox";
import { ResponsivePie } from "@nivo/pie";
import { API_URL } from "../utils";

function Action() {
    const [data, setData] = useState({
        primary_market_nft_buy_total: 0,
        secondary_market_nft_buy_total: 0,
        primary_market_nft_sell_total: 0,
        secondary_market_nft_sell_total: 0,
    });

    const [transactions, setTransactions] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const { org_id, org_name, org_country } = useSelector(state => state.auth);
    const [theme, colorMode] = useMode();
    const colors = tokens(theme.palette.mode);
    const isXlDevices = useMediaQuery("(min-width: 1260px)");
    const isMdDevices = useMediaQuery("(min-width: 724px)");
    const isXsDevices = useMediaQuery("(max-width: 436px)");
    const marketTypeMap = {
        primary_market: 'Primary Market',
        secondary_market: 'Secondary Market'
    };

    useEffect(() => {
        console.log("ORG ID IS ", org_id)
        console.log("ORG NAME IS ", org_name)
        console.log("ORG Country IS ", org_country)
        fetchData();
        fetchTransactions();
    }, [org_id]);

    const fetchData = async () => {
        try {
            const res = await axios.get(
                `${API_URL}/organisations/dashboard/data/${org_id}/nft-history/`
            );
            setData(res.data);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    const fetchTransactions = async () => {
        try {
            const res = await axios.get(
                `${API_URL}/nfts/transactions-history/`
            );
            setTransactions(res.data);
        } catch (error) {
            console.error("Error fetching transactions", error);
            setTransactions([]); // Set default value on error
        }
    };

    const piedata = [
        { id: 'Primary Buy', value: data.primary_market_nft_buy_total },
        { id: 'Secondary Buy', value: data.secondary_market_nft_buy_total },
        { id: 'Primary Sell', value: data.primary_market_nft_sell_total },
        { id: 'Secondary Sell', value: data.secondary_market_nft_sell_total },
      ];

    return (
        <Box m="20px">
            <Box
                display="grid"
                gridTemplateColumns={
                    isXlDevices
                        ? "repeat(12, 1fr)"
                        : isMdDevices
                        ? "repeat(6, 1fr)"
                        : "repeat(3, 1fr)"
                }
                gridAutoRows="140px"
                gap="20px"
            >
                <Box
                    gridColumn="span 3"
                    bgcolor='#E3BC9A21'
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title={data.primary_market_nft_buy_total}
                        subtitle="Total NFTs bought: Primary Market"
                        subtitleColor="black"
                    />
                </Box>
                <Box
                    gridColumn="span 3"
                    bgcolor='#E3BC9A21'
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title={data.secondary_market_nft_buy_total}
                        subtitle="Total NFTs bought: Secondary Market"
                        subtitleColor="black"
                    />
                </Box>
                <Box
                    gridColumn="span 3"
                    bgcolor='#E3BC9A21'
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title={data.primary_market_nft_sell_total}
                        subtitle="Total NFTs sold: Primary Market"
                        subtitleColor="black"
                    />
                </Box>
                <Box
                    gridColumn="span 3"
                    bgcolor='#E3BC9A21'
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title={data.secondary_market_nft_sell_total}
                        subtitle="Total NFTs sold: Secondary Market"
                        subtitleColor="black"
                    />
                </Box>

                {/* Line Chart */}
                <Box
                  gridColumn={
                    isXlDevices ? "span 8" : isMdDevices ? "span 6" : "span 3"
                  }
                  gridRow="span 2"
                  bgcolor='#E3BC9A21'
                >
                  <Box
                    mt="25px"
                    px="30px"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        fontWeight="600"
                        color={colors.black}
                      >
                        Carbon Credits vs Price
                      </Typography>
                    </Box>
                  </Box>
                  <Box height="250px" mt="-20px">
                    <LineChart isDashboard={true} setRevenueData={setRevenueData} />
                  </Box>
                </Box>
                
                {/* ---------------RECENT transactions ------------------ */}
                <Box
                    gridColumn={isXlDevices ? "span 4" : "span 3"}
                    gridRow="span 2"
                    bgcolor='#E3BC9A21'
                    overflow="auto"
                >
                    <Box borderBottom={`4px solid ${colors.black}`} p="15px">
                        <Typography color={colors.black} variant="h6" fontWeight="600">
                          Recent transactions
                        </Typography>
                    </Box>

                    {transactions.length > 0 ? (
                        transactions.map((transaction, index) => (
                            <Box
                                key={`${transaction.nft_id}-${index}`}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                borderBottom={`3px solid ${colors.primary[500]}`}
                                p="15px"
                            >
                                <Box>
                                    <Typography
                                        color="#8B4513"
                                        variant="h7"
                                        fontWeight="600"
                                    >
                                        {transaction.nft_id}
                                    </Typography>
                                    <Typography color={colors.black}>
                                        {transaction.org_name || ""}
                                    </Typography>
                                </Box>
                                <Typography color={colors.black}>
                                    {transaction.carbon_credits} tCO2eq
                                </Typography>
                                <Box
                                    bgcolor='#E3BC9A21'
                                    p="5px 10px"
                                    borderRadius="5px"
                                >
                                    {marketTypeMap[transaction.market_type] || transaction.market_type}
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <Box p="15px">
                            <Typography color={colors.black}>
                                No transactions found.
                            </Typography>
                        </Box>
                    )}
                </Box>
                
                {/* PIE Chart for Data Visualisation */}
                <Box
                    gridColumn={isXlDevices ? "span 4" : "span 3"}
                    gridRow="span 2"
                    backgroundColor='#E3BC9A21'
                    p="30px"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center" // Ensure content is centered
                    >
                    <Typography variant="h6" fontWeight="600" color={colors.black}>
                        Environmental Impact
                    </Typography>
                    {/* Adjust width and height of the Pie chart based on screen size */}
                    <Box 
                        width={isMdDevices ? "400px" : "300px"} 
                        height={isMdDevices ? "400px" : "300px"} 
                        mt="25px"
                    >
                        <ResponsivePie
                        data={piedata}
                        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        colors={['#967474', '#968f74', '#85947e', '#768c8c']}
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                        radialLabelsSkipAngle={10}
                        radialLabelsTextColor="#000000"
                        radialLabelsLinkColor={{ from: 'color' }}
                        sliceLabelsSkipAngle={10}
                        sliceLabelsTextColor="#000000"
                        theme={{
                            labels: {
                            text: {
                                fill: '#000000',
                            },
                            },
                            legends: {
                            text: {
                                fill: '#000000',
                            },
                            },
                        }}
                        />
                    </Box>
                </Box>

                

                {/* Bar Chart */}
                <Box
                    gridColumn={isXlDevices ? "span 4" : "span 3"}
                    gridRow="span 2"
                    backgroundColor='#E3BC9A21'
                >
                    <Typography
                        variant="h6"
                        fontWeight="600"
                        sx={{ p: "30px 30px 0 30px" }}
                    >
                        Revenue Generated
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height="250px"
                        mt="-20px"
                    >
                        <BarChart isDashboard={true} />
                    </Box>
                </Box>

                {/* Geography Chart */}
                <Box
                    gridColumn={isXlDevices ? "span 4" : "span 3"}
                    gridRow="span 2"
                    backgroundColor='#E3BC9A21'
                    padding="30px"
                >
                    <Typography variant="h6" fontWeight="600" mb="15px">
                        Geographical Distribution
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height="200px"
                    >
                        <GeographyChart isDashboard={true} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Action;
