import React, { useState } from 'react'
import { Typography } from '@mui/material'
import './footer.css'
import { Link } from 'react-router-dom'
// import logo from '../../assets/DCarbonX_logo.png'
import logo from '../../assets/DCarbonXlogo_brown.png'
import { useDispatch, useSelector } from 'react-redux';

function Footer() {
    const { guideStatus, refundStatus } = useSelector(state => state.others)
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [refund, setRefund] = useState(false)
    const [guide, setGuide] = useState(false)

    return (
        <footer className='rounded-lg py-3 bg-light'>
            <div className="container-fluid">
                <div className="row">

                    <div className="col-12">
                        <Typography sx={{ textAlign: 'center', fontSize: "15px" }}>Nash Sarl © {new Date().getFullYear()}</Typography>
                    </div>

                    <div className="col-12">
                        <div className='row d-flex flex-row justify-content-center gap-2'>
                            <span className='about' onClick={() => setShow(true)}>About</span>
                            <Link className='text-center' to='/privacy-statement'>Privacy Statement</Link>
                            <span className='about' onClick={() => setRefund(true)}>Refund Policy</span>
                            <span onClick={() => setGuide(true)} className='guide'>Guide to Register</span>
                            <Typography sx={{ textAlign: 'center', fontSize: "15px" }}>
                                <a rel="noreferrer" href='https://nashfintechx.com/contactus.html' target="_blank">Contact</a>
                            </Typography>
                        </div>
                    </div>

                    <div className="col-12">
                        <Typography className='m-0' sx={{ textAlign: 'center', fontSize: "15px", fontWeight: 400 }}>
                            DCarbonX<strong><sup>&reg;</sup></strong> -  A product of <a href="https://nashfintechx.com" rel="noreferrer" target="_blank">Nash fintechX</a>
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={`about-model ${show ? 'show' : 'hide'}`}>
                <div className='main-con'>
                    <div className='about-header'>
                        <p className='my-0 text-center'>About DCarbonX</p>
                        <div onClick={() => setShow(false)} className='close'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                        </div>
                    </div>
                    <div className='about-body'>
                        <div className='image'>
                            <img src={logo} alt="" />
                        </div>
                        <p>DCarbonX® is a registered trademark of Nash fintechX. Nash fintechX is an authorised SaaS provider and is registered in Luxembourg as Nash Sarl. 
                            Government authorization number: 10120202/0. <br /> RCS Luxembourg B 248581 - VAT LU32526458.</p>
                        <div className='d-flex flex-row justify-content-center align-items-center mt-4'>
                            <button className='btn px-3 py-2' style={{ backgroundColor: "#7B3F00"}}><a href="https://nashfintechx.com/" target='_blank'>Know about Nash fintechX</a></button>
                        </div>
                        <div className='d-flex flex-row justify-content-center align-items-center mt-4 text-secondary'>
                            <span>Nash Sarl © {new Date().getFullYear()}. All rights reserved.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`guide-model ${guide || guideStatus ? 'show' : 'hide'}`}>
                <div className='main-con'>
                    <div className='guide-header'>
                        <p className='my-0 text-center'>Guide to Register</p>
                        <div onClick={() => {
                            dispatch({ type: 'GUIDE_FALSE' })
                            setGuide(false)
                        }} className='close'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                            </svg>
                        </div>
                    </div>
                    <div className='guide-body'>
                        <div className='image'>
                            <h4>Guide to Register</h4>
                        </div>
                        <p>Please follow the steps listed below, to register your organisation:</p>
                        <ol>
                            <li>
                                <p>Go to <a className='text-primary' href="https://dcarbonx.io/register/request">https://dcarbonx.io/register/request</a> to initiate the registration
                                    process.</p>
                            </li>
                            <li>
                                <p>You will receive a password in the email you used to fill the
                                    <strong> Register </strong>form above.</p>
                            </li>
                            <li>
                                <p>Login using the same email and the password you received at <a className='text-primary' href="https://dcarbonx.io/login">https://dcarbonx.io/login</a></p>
                            </li>
                            <li>
                                <p>Once you are logged in, go to the <strong>Basic details</strong> section of the Registration form <a className='text-primary' href="https://dcarbonx.io/register/form">https://dcarbonx.io/register/form</a></p>
                            </li>
                            <li>
                                <p>After you have submitted the information in the <strong>Basic details</strong> section of the registration form, please go to the <strong>Payment details</strong>.</p>
                            </li>
                            <li>
                                <p>Once you click <strong>Payment details</strong> you would be redirected to Stripe. Complete the registration in Stripe to ensure that you receive payments directly in your bank account for the carbon credits you sell through our market.</p>
                            </li>
                            <li>
                                <p>There is no fee to register on DCarbonX. We will charge 3% fee per transaction for the carbon credits sold through our market.</p>
                            </li>
                            <li>
                                <p>The registered organisation can create a fractionalized NFT through our market for each carbon credit certificate they issue. 
                                    Transaction fee per NFT will be borne by the registered organisation.</p>
                            </li>
                            <li>
                                <p>We will give a demo on how to create an NFT through our market once the organisation has registered. The registered organisation can schedule a call for demo by writing to: <a className='text-primary' href="createnft@dcarbonx.nashfintechx.com">createnft@dcarbonx.nashfintechx.com</a></p>
                            </li>
                        </ol>
                        <p>
                            Please don’t hesitate to ask any questions or schedule a meeting to provide support during the registration process. You may write to: <a className='text-primary' href="createnft@dcarbonx.nashfintechx.com">contact@nashfintechx.com</a>
                        </p>
                    </div>
                </div>
            </div>

            <div className={`refund-model ${refund || refundStatus ? 'show' : 'hide'}`}>
                <div className='main-con'>
                    <div className='about-header'>
                        <p className='my-0 text-center'>Refund Policy</p>
                        <div onClick={() => {
                            setRefund(false)
                            dispatch({ type: 'REFUND_FALSE' })
                        }} className='close'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                            </svg>
                        </div>
                    </div>
                    <div className='refund-body'>
                        <div className='d-flex justify-content-center align-items-center mb-3'>
                            <h4>Refund Policy</h4>
                        </div>
                        <p>Once you have purchased a carbon credit NFT or a unit of a fractionalized 
                            NFT of a carbon credit, the transaction is recorded permanently on the 
                            blockchain. The refund policy will be as per the terms and conditions of the 
                            issuing organization. In case a claim is made for refund, which is authorized 
                            by the issuing organization, the transaction fee for the same will be borne by 
                            the issuing organization by purchasing the sold carbon credit through 
                            DCarbonX, where the entity claiming for a refund has put it up for sale.</p>
                        <div className='d-flex flex-row justify-content-center align-items-center mt-4 text-secondary'>
                            <span>Nash Sarl © {new Date().getFullYear()}. All rights reserved.</span>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer