import React from 'react'
import axios from 'axios'
import { API_URL, CONFIG } from '../utils';

const Account = () => {
    const conenctStripe = () => {
        axios.get(`${API_URL}/organisations/create-stripe-connect/`, CONFIG)
        .then(res => {
            // if(res.data.status)
            console.log(res.data)
            window.location.replace(res.data.account_link.url);
        })
        .error(error => {
            console.log(error.message)
        })
    }
    
    return (
        <div className='page bg'>
            <div className="container">
                Account
                <br />
                <button className="btn btn-secondary" onClick={conenctStripe}>Onboard to Stripe</button>
            </div>
        </div>
    )
}

export default Account
