import React, { useState, useEffect, useReducer } from 'react'
import axios from 'axios'
import swal from 'sweetalert';
import { useNavigate, Link } from 'react-router-dom'
import { useForm } from "react-hook-form";
import HorizontalTimeline from 'react-horizontal-timeline';
import {
    Button, Grid, Typography, Box, TextField, Switch, CircularProgress, FormControlLabel,
    FormControl, Radio, RadioGroup, FormLabel
} from '@mui/material'
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { SelectChangeEvent } from '@mui/material/Select';
import { useDispatch } from 'react-redux';

import { API_URL, CONFIG } from '../../utils';
import { categories, allCountryList, varificationStandards } from '../data';

import './registrationForm.css';

const reducer = (state, action) => {
    switch (action.type) {
        case "INFO":
            return {
                count: 1,
                formHeading: 'Organisation Information'
            }
        case "DETAIL":
            return {
                count: 2,
                formHeading: 'Organisation Detail'
            }
        case "REGULATORY":
            return {
                count: 3,
                formHeading: 'Regulatory Information'
            }
        // case "PROJECT":
        //     return {
        //         count: 4,
        //         formHeading: 'Project Information'
        //     }
        case "REVIEW":
            return {
                count: 4,
                formHeading: 'Review Submit'
            }
        default:
            return state;
    }
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const RegistrationForm = () => {

    const dispatchs = useDispatch();
    const [showSpinner, setShowSpinner] = useState(false)
    const [value, setValue] = useState('bs')

    const navigate = useNavigate();

    const { register, control, errors, formState, handleSubmit } = useForm({
        defaultValues: {
            // < Org
            organisationName: '',
            website: '',
            logo: '',
            organisationDescription: '',
            organisationCountry: '',
            organisationAddress: '',
            relevantRegistration: '',
            // authorized representative < Org
            representativeName: '',
            position: '',
            email: '',
            linkedIn: '',
            code: '',
            phone: '',
            // regulatory information < Org
            anyRegulatedJurisdiction: false,
            confirmJurisdiction: '',
            // bank information
            beneficiaryAccountCountry: '',
            typeOfAccount1: '',
            typeOfAccount2: '',
            bankCode: '',
            nameOfBank: '',
            cityOfBank: '',
            accountNumber: '',

            beneficiaryName: '',
            beneficiaryAddress: '',
            zipCity: '',
            beneficiaryCountry: '',

        },
    })

    const [orgCountry, setOrgCountry] = useState('')
    const [countries, setCountries] = useState(allCountryList)
    const [state, dispatch] = useReducer(reducer, { count: 1, formHeading: 'Organisation Information' })

    const [regulatedJurisdiction, setRegulatedJurisdiction] = useState(false)
    const [orgDesc, setOrgDesc] = useState('')

    const [checkErrorMsg, setCheckErrorMessage] = useState(null)

    const [guideChecked, setGuideChecked] = useState(false)
    const [websiteErr, setWebsiteErr] = useState('')

    useEffect(() => {
        const access = localStorage.getItem('access')

        if (!access) {
            localStorage.setItem('history', 'register/form')
        }
    }, [])

    const onSubmit = data => {

        setShowSpinner(true)

        const web = data.website;
        if (web.length && !web.includes("http")) {
            setWebsiteErr("Website must contain http or https.")
            setShowSpinner(false)
            return;
        } else {
            setWebsiteErr('')
        }

        let formData = getFormData(data);

        axios.post(`${API_URL}/organisations/new/`, formData, CONFIG)
            .then(res => {
                if (res.status === 201) {
                    swal({
                        title: "Success",
                        text: "User Registration Success!",
                        icon: "success",
                        button: false,
                        timer: 900,
                    });
                    localStorage.setItem("role", "Organisation");
                    localStorage.setItem("org_id", res.data?.id);
                    localStorage.setItem("org_name", res.data?.organisation_name);

                    dispatchs({
                        type: "UPDATE_ORG_DATA",
                        payload: {
                            role: 'Organisation',
                            org_id: res.data?.id,
                            org_name: res.data?.organisation_name
                        },
                    });

                    navigate('/')

                } else {
                    swal({
                        title: "Failed",
                        text: "Registration Failed!",
                        icon: "error",
                        button: false,
                        timer: 1200,
                    });

                }
                setShowSpinner(false)
            })
            .catch((error) => {
                setShowSpinner(false)
                if (error?.response?.data?.logo) {
                    swal({
                        title: "Failed",
                        text: error?.response?.data?.logo[0],
                        icon: "error",
                        button: false,
                        timer: 1200,
                    });
                }
                console.log(error.response.data)
            })
    }

    const getFormData = data => {
        let formData = new FormData()
        formData.append('owner', localStorage.getItem('user_id'));
        formData.append('organisation_name', data.organisationName);
        formData.append('website', data.website);
        formData.append('logo', data.logo[0]);
        formData.append('organisation_description', orgDesc);
        formData.append('organisation_country', orgCountry);
        formData.append('organisation_address', data.organisationAddress);
        formData.append('relevant_registration', data.relevantRegistration);

        formData.append('representative_name', data.representativeName);
        formData.append('position', data.position);
        formData.append('email', data.email);
        formData.append('linked_in', data.linkedIn);
        formData.append('phone', data.phone);
        if (value === "bs") {
            formData.append("whitelist", false);
        }
        else if (value === "cbs") {
            formData.append("whitelist", true);
        }

        formData.append('any_regulated_jurisdiction', regulatedJurisdiction);
        formData.append('confirm_jurisdiction', regulatedJurisdiction ? data.confirmJurisdiction : '');

        return formData;
    }

    const onError = (error) => console.log(error);

    const checkboxChange = e => {
        if (e.target.checked === false) {
            setCheckErrorMessage("You need to agree to our data usage terms.")
        }
        else {
            setCheckErrorMessage(false)
        }
    }

    const checkboxChangeConfirm = e => {
        if (e.target.checked === true) {
            setGuideChecked(true)
        }
        else {
            setGuideChecked(false)
        }
    }
    const [timeLineState, setTimeLineState] = useState({
        value: 0, previous: 0
    })
    const values = [
        1, 2, 3, 4
    ];
    useEffect(() => {
        setTimeLineState({
            ...timeLineState,
            value: state.count - 1
        })
    }, [state])

    const handleRadio = e => {
        setValue(e.target.value);
    }

    return (
        <div className="bg py-4 page">
            <div className='container px-4 px-lg-0 px-md-0 px-sm-0' >
                {
                    localStorage.getItem('role') ? (
                        <>
                            <Typography variant='h4' sx={{ textAlign: 'center', fontSize: '25px' }}>
                                {state.formHeading}
                            </Typography>
                            <hr></hr>
                            <Grid display={{ md: 'none', sm: 'block' }} sx={{ padding: '0px 4px' }}>
                                <div style={{ width: '100%', height: '60px' }}>
                                    <HorizontalTimeline
                                        index={timeLineState.value}
                                        isTouchEnabled={true}
                                        labelWidth={250}
                                        getLabel={(data) => {
                                            if (data === 1) {
                                                return 'Organisation Information'
                                            }
                                            if (data === 2) {
                                                return 'Organisation Detail'
                                            }
                                            if (data === 3) {
                                                return 'Regulatory Information'
                                            }
                                            if (data === 4) {
                                                return 'Review & Submit'
                                            }
                                        }}
                                        indexClick={(index) => {
                                            setTimeLineState(
                                                {
                                                    value: index,
                                                    previous: timeLineState.value
                                                }
                                            );
                                        }}
                                        values={values}
                                    />
                                </div>
                            </Grid>
                            <Grid container sx={{ mt: 4 }}>
                                <Grid item display={{ md: 'none', lg: 'block', }} lg={1} />
                                <Grid item lg={3} md={4} display={{ md: 'block', xs: 'none', lg: 'block', }}>
                                    <Timeline>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot color="dark" />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>Organisation Information</TimelineContent>
                                        </TimelineItem>

                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot color={state.count >= 2 ? "dark" : "primary"} />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>Organisation Detail</TimelineContent>
                                        </TimelineItem>

                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot color={state.count >= 3 ? "dark" : "primary"} />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>Regulatory Information</TimelineContent>
                                        </TimelineItem>

                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot color={state.count === 4 ? "dark" : "primary"} />
                                            </TimelineSeparator>
                                            <TimelineContent>Review & Submit</TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Grid>

                                <Grid item md={8} lg={7} sx={{ overflowY: 'auto', height: '67vh', padding: '0px 4px' }}>
                                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                                        <Box sx={{ maxWidth: '100%', my: 2 }} >
                                            <TextField
                                                fullWidth
                                                required
                                                name='organisationName'
                                                label="Name"
                                                control={control}
                                                onFocus={() => {
                                                    dispatch({ type: "INFO" })
                                                }}

                                                inputRef={register({
                                                    required: "Organisation name is required.",
                                                    minLength: {
                                                        value: 4,
                                                        message: "Must have at least 4 characters"
                                                    },
                                                })
                                                }
                                                error={Boolean(errors.organisationName)}
                                                helperText={errors.organisationName?.message ? errors.organisationName?.message : "Please enter your Organisation's name"}
                                            />

                                            <TextField
                                                fullWidth
                                                name='website'
                                                label="Website"
                                                required
                                                control={control}
                                                onFocus={() => {
                                                    dispatch({ type: "INFO" })
                                                }}
                                                inputRef={register({
                                                    required: "Website name is required.",
                                                    pattern: {
                                                        value: /\bhttps?:\/\/\S+/g,
                                                        message: "Website must contain http or https.",
                                                    },
                                                })}
                                                error={Boolean(errors.website)}
                                                helperText={errors.website?.message ? errors.website?.message : "Website of your Organisation"}

                                            />
                                            {/* {websiteErr?.length ? <p className='text-danger' style={{ fontSize: 14 }}>{websiteErr}</p> : ('')} */}

                                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        type="file"
                                                        fullWidth
                                                        required
                                                        accept=".jpg, .jpeg, .png"
                                                        name='logo'
                                                        label="Logo"
                                                        control={control}
                                                        onFocus={() => {
                                                            dispatch({ type: "INFO" })
                                                        }}
                                                        inputRef={register()}
                                                        error={Boolean(errors.logo)}
                                                        helperText={"Logo for your Organisation"}
                                                        focused
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <textarea
                                                        placeholder='Organisation description *'
                                                        name="organisationDescription"
                                                        id="orgDescId"
                                                        required
                                                        onFocus={() => {
                                                            dispatch({ type: "INFO" })
                                                        }}
                                                        cols="35" rows="3"
                                                        onChange={e => {
                                                            setOrgDesc(e?.target?.value)
                                                        }}
                                                    >
                                                    </textarea>

                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                            <Typography variant='h5' sx={{ mb: 1 }}>Authorized representative</Typography>
                                            <TextField
                                                fullWidth
                                                name='representativeName'
                                                label="Name"
                                                control={control}
                                                required
                                                onFocus={() => {
                                                    dispatch({ type: "DETAIL" })
                                                }}
                                                inputRef={register({
                                                    required: "Representative Name is required.",
                                                    minLength: {
                                                        value: 4,
                                                        message: "Must have at least 4 characters"
                                                    },
                                                    pattern: {
                                                        value: /^[a-z][A-Z\s.]{3,255}$/i,
                                                        message: "Invalid character for Name"
                                                    }
                                                })
                                                }
                                                error={Boolean(errors.representativeName)}
                                                helperText={errors.representativeName?.message ? errors.representativeName?.message : "Your Name"}
                                            />

                                            <TextField
                                                fullWidth
                                                name='position'
                                                label="Position/Role"
                                                control={control}
                                                required
                                                onFocus={() => {
                                                    dispatch({ type: "DETAIL" })
                                                }}
                                                inputRef={register({
                                                    required: "Position/Role is required.",
                                                    minLength: {
                                                        value: 2,
                                                        message: "Must have at least 2 characters"
                                                    },
                                                })
                                                }
                                                error={Boolean(errors.position)}
                                                helperText={errors.position?.message ? errors.position?.message : "Your Position/Role"}
                                            />

                                            <TextField
                                                type="email"
                                                fullWidth
                                                name="email"
                                                label="Email"
                                                required
                                                control={control}
                                                onFocus={() => {
                                                    dispatch({ type: "DETAIL" })
                                                }}
                                                inputRef={register({
                                                    required: "Email is required.",
                                                    minLength: {
                                                        value: 6,
                                                        message: "Must have at least 6 characters"
                                                    },
                                                    pattern: {
                                                        value: /^\S+@\S+$/,
                                                        message: "Invalid Email address"
                                                    }
                                                })
                                                }
                                                error={Boolean(errors.email)}
                                                helperText={errors.email?.message ? errors.email?.message : "Your Email"}
                                            />

                                            <TextField
                                                fullWidth
                                                name="linkedIn"
                                                label="LinkedIn Profile"
                                                control={control}
                                                required
                                                onFocus={() => {
                                                    dispatch({ type: "DETAIL" })
                                                }}
                                                inputRef={register({
                                                    required: "LinkedIn is required.",
                                                    minLength: {
                                                        value: 8,
                                                        message: "Must have at least 8 characters"
                                                    },
                                                })
                                                }
                                                error={Boolean(errors.linkedIn)}
                                                helperText={errors.linkedIn?.message ? errors.linkedIn?.message : "Your LinkedIn profile"}
                                            />

                                            <TextField
                                                fullWidth
                                                label="Phone Number"
                                                name='phone'
                                                required
                                                control={control}
                                                onFocus={() => {
                                                    dispatch({ type: "DETAIL" })
                                                }}
                                                inputRef={register({
                                                    required: "Phone is required.",
                                                    minLength: {
                                                        value: 8,
                                                        message: "Must have at least 8 characters"
                                                    },
                                                })
                                                }
                                                error={Boolean(errors.phone)}
                                                helperText={errors.phone?.message ? errors.phone?.message : "Phone number including country code"}
                                            />
                                        </Box>

                                        <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                            <Typography variant='h5' sx={{ mb: 1 }}>Regulatory information</Typography>

                                            <FormControlLabel
                                                name='anyRegulatedJurisdiction'
                                                control={<Switch
                                                    onChange={(prev, val) => {
                                                        setRegulatedJurisdiction(val)
                                                        dispatch({ type: "REGULATORY" })
                                                        return;
                                                    }}
                                                    sx={{
                                                        "&.Mui-checked": {
                                                            color: "#95cc97"
                                                        },
                                                    }}
                                                />}
                                                label="Are you regulated in any jurisdiction?" />

                                            {regulatedJurisdiction === true ? (<>

                                                <TextField
                                                    sx={{ mt: 2 }}
                                                    fullWidth
                                                    name='confirmJurisdiction'
                                                    label="Please confirm the jurisdiction?"
                                                    required
                                                    control={control}
                                                    onFocus={() => {
                                                        dispatch({ type: "REGULATORY" })
                                                    }}
                                                    inputRef={register({
                                                        required: "Confirm Jurisdiction is required.",
                                                    })
                                                    }
                                                    error={Boolean(errors.confirmJurisdiction)}
                                                    helperText={errors.confirmJurisdiction?.message ? errors.confirmJurisdiction?.message : "Confirm your jurisdiction"}
                                                />

                                                <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                                    <Typography variant='p' sx={{ mb: 1 }}>Place of Registration or Incorporation</Typography>

                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} md={4}>
                                                            <select required className="form-select form-select-lg mb-3 mt-2" aria-label=".form-select-lg example"
                                                                onChange={(e) => setOrgCountry(e?.target?.value)}
                                                                onFocus={() => {
                                                                    dispatch({ type: "REGULATORY" })
                                                                }}>
                                                                <option value="" selected hidden disabled>Country</option>
                                                                {
                                                                    countries?.map(country => (
                                                                        <option key={country} value={country}>{country}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </Grid>

                                                        <Grid item xs={12} md={8}>
                                                            <Box sx={{ maxWidth: '100%' }}>
                                                                <TextField
                                                                    fullWidth
                                                                    required
                                                                    label="Address"
                                                                    name='organisationAddress'
                                                                    control={control}
                                                                    onFocus={() => {
                                                                        dispatch({ type: "REGULATORY" })
                                                                    }}
                                                                    inputRef={register({
                                                                        required: "Address is required.",
                                                                    })
                                                                    }
                                                                    error={Boolean(errors.organisationAddress)}
                                                                    helperText={errors.organisationAddress?.message ? errors.organisationAddress?.message : "Full address of your Organisation"}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>

                                                    <TextField
                                                        fullWidth
                                                        required
                                                        name='relevantRegistration'
                                                        label="Relevant registration or Incorporation identifier"
                                                        control={control}
                                                        onFocus={() => {
                                                            dispatch({ type: "REGULATORY" })
                                                        }}
                                                        inputRef={register({
                                                            required: "Relevant registration or Incorporation identifier is required.",
                                                        })
                                                        }
                                                        error={Boolean(errors.relevantRegistration)}
                                                        helperText={errors.relevantRegistration?.message ? errors.relevantRegistration?.message : "Relevant registration or Incorporation identifier"}
                                                    />
                                                </Box>


                                            </>) : (' ')}


                                        </Box>
                                        {/* end checking isJurisdiction  */}

                                        <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                            <FormControl>
                                                <div id="demo-controlled-radio-buttons-group">How do you intend to use our platform?</div>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={value}
                                                    onChange={handleRadio}
                                                    onFocus={() => {
                                                        dispatch({ type: "REGULATORY" })
                                                    }}
                                                >
                                                    <FormControlLabel value="bs" control={<Radio />} label="Buy and Sell Carbon Credit NFTs" />
                                                    <FormControlLabel value="cbs" control={<Radio />} label="Create, Buy and Sell Carbon Credit NFTs" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>


                                        <label className="form-check-label mb-2">
                                            <input className="form-check-input" type="checkbox" name="checked" onChange={checkboxChange} onFocus={() => {
                                                dispatch({ type: "REVIEW" })
                                            }} />
                                            <span className='ml-2 mb-2' style={{ marginLeft: "10px", fontSize: '14px', color: '#00000099' }}>I allow DCarbonX - Nash fintechX to store my
                                                data and use it for purposes described in the <Link className='text-primary' style={{ fontSize: '14px', fontWeight: '400' }} to="/privacy-statement">Privacy Statement</Link>, among which is to provide the services offered by the platform.
                                                I am aware that I can revoke this right at any instant by a written email to Nash fintechX at <span className='text-primary'>contact@nashfintechx.com</span></span>
                                        </label>
                                        <div className='mb-2'>
                                            <input className="form-check-input" type="checkbox" name="checked2" onChange={checkboxChangeConfirm}
                                                onFocus={() => {
                                                    dispatch({ type: "REVIEW" })
                                                }} />
                                            <span className='ml-2' style={{ marginLeft: "10px", fontSize: '14px', color: '#00000099' }}>I have read the <span onClick={() => dispatchs({ type: 'GUIDE_TRUE' })} style={{ cursor: 'pointer' }} className='text-primary'>Guide to Register</span> and <span onClick={() => dispatchs({ type: 'REFUND_TRUE' })} style={{ cursor: 'pointer' }} className='text-primary'>Refund Policy</span> and agree with the registration conditions to become a partner of Nash fintechX</span>
                                        </div>
                                        {
                                            checkErrorMsg && <Typography variant='p' sx={{ color: 'red', fontSize: 14, my: 2, display: 'block' }}>{checkErrorMsg}</Typography>
                                        }

                                        {/* {
                                            showSpinner &&
                                            <Box sx={{ display: 'flex', mb: 2 }}>
                                                <CircularProgress />
                                            </Box>
                                        } */}

                                        <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                            <Button
                                                color='dark'
                                                variant="contained"
                                                type='submit'
                                                sx={{ color: 'white' }}
                                                disabled={Boolean(Object.keys(formState.errors).length || checkErrorMsg?.length || checkErrorMsg === null || guideChecked === false || showSpinner)}
                                            >{showSpinner ? "Loading..." : 'Submit'}</Button>
                                        </Box>
                                    </form>
                                </Grid>

                                <Grid item display={{ md: 'none', lg: 'block', }} lg={1} />

                            </Grid>
                        </>
                    ) : (
                        <Box className='d-flex flex-column page'>
                            <Typography className='login_text' variant='h4' sx={{ textAlign: 'center', color: 'black', marginBottom: '10px' }}>
                                Please login to use this feature
                            </Typography>
                            <Box className='text-center'>
                                <Button color='success' variant="outlined" onClick={() => navigate('/login')}>Login here</Button>
                            </Box>
                        </Box>
                    )
                }
            </div>
        </div>
    )
}

export default RegistrationForm
