import React, { useState, useEffect } from "react";
import { PeraWalletConnect } from "@perawallet/connect";
import { ALGO_MakeAlgoSignerConnect as MakeAlgosigner } from "@reach-sh/stdlib";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

import {
  Button as ReactButton,
  Form,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { Typography } from "@mui/material";
import FormPartOne from "./FormPartOne";
import FormPartTwo from "./FormPartTwo";
import FormPartThree from "./FormPartThree";
import FormPartFour from "./FormPartFour";
import FormPreview from "./FormPreview";
import HorizontalTimeline from "react-horizontal-timeline";

import { API_URL, CONFIG } from "../../utils";
import "./form.css";

import MyAlgoConnect from "@randlabs/myalgo-connect";
import ARC_69 from "../../utils/functions/arc69";
import { walletAtom } from "../../utils/wallet";
import { useAtom } from "jotai";
import MakePeraConnect from "../../utils/helper";
import { loadStdlib } from "@reach-sh/stdlib";
import { networkConfig } from "../../config/network";
import { BNtoN } from "../../utils/functions";

const pinataSDK = require("@pinata/sdk");
const algosdk = require("algosdk");

// const jwt =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJlYzEwNTJjZC1jOGE4LTRmMWQtYTU1OS00NGQyNGI2NThhZDkiLCJlbWFpbCI6ImNvbnRhY3RAbmFzaGZpbnRlY2h4LmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImlkIjoiRlJBMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfSx7ImlkIjoiTllDMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiI1ZTY2NmQ1MTU0MjBmNGFkZWMzMyIsInNjb3BlZEtleVNlY3JldCI6IjJiZmI0NmQzZGVmNmRjNDdhZTYyYTM1ZjI3ZGJiN2QyMzlhMDgwMzI3YzE2NTcyNjc3MzA5NzBjZTQ1NTQ5NTEiLCJpYXQiOjE2NzIxMzU1NDJ9.Dvi8NBQw1bpYVkzZpjfEeUe7O9RuaifHXreDVWjmsF4";
// const pinataApiKey = "5e666d515420f4adec33";
// const pinataApiSecret =
//   "2bfb46d3def6dc47ae62a35f27dbb7d239a080327c1657267730970ce4554951";

const jwt =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJjYzNmMDY5OC02MTFmLTQ3MGItODUyOC01ZTgyYTRiOGJjZDAiLCJlbWFpbCI6Imh1bWF5dW4ua2FiaXJAbmFzaGZpbnRlY2h4LmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImlkIjoiRlJBMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfSx7ImlkIjoiTllDMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiJiYzYxOTY5NTM2MTU4ZWVkYjc0YSIsInNjb3BlZEtleVNlY3JldCI6IjYyYmQ2YTU2MGI1MThkZjIyY2Q3MjIwZmY5YWU3NzJlMGYzYmNjNjM2ZDExNmEwOWZlZDQ1YmZjY2UwMjYzZTMiLCJpYXQiOjE2ODAyNTk4ODV9.waYVv5LzZXqXItP8chTcn1kp7-B04uLo8eGrPKpfeAk";
const pinataApiKey = "bc61969536158eedb74a";
const pinataApiSecret =
  "62bd6a560b518df22cd7220ff9ae772e0f3bcc636d116a09fed45bfcce0263e3";

const pinata = pinataSDK(pinataApiKey, pinataApiSecret);

const CryptoJS = require("crypto-js");

let reach = loadStdlib({ REACH_NO_WARN: "Y" });
const NftForm = () => {
  const [page, setPage] = useState(0);
  const [attribute, setAttribute] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [message, setMessage] = useState(null);
  const [formData, setFormData] = useState({
    file: "",
    fileSrc: "",
    fileType: "",
    certificateOwner: "",
    certificateOwnerEmail: "",
    description: "",
    maximumSupply: "",
    hash: "",
    address: "",
    total: "",
    decimals: 0,
    assetName: "",
    assetId: 0,
    unitName: "",
    co2Offset: 0,
    assetURL: "",
    assetMetadataHash: "",
    suggestedParams: {},
  });
  // defaultFrozen: "", freeze: "", manager: "", clawback: "", reserve: "",

  // ? This holds the data for  our wallet Object
  const [wallet, setWallet] = useAtom(walletAtom);
  console.log("wallet:", wallet);
  // ? Important for everything to work

  const [param, setParam] = useState({});

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [balance, setBalance] = useState(0);
  const [balanceMsg, setBalanceMsg] = useState("");
  const [assetUrl, setAssetUrl] = useState("");
  const [mdata, setMData] = useState(null);
  const [trxId, setTrxId] = useState(null);
  const [stringObj, setStringObj] = useState("");
  const [imageHashCode, setImageHashCode] = useState("");
  const [nftMsg, setNftMsg] = useState("");
  const [checkok, setCheckok] = useState(false);
  const [nftok, setNftok] = useState(false);
  const [confirmok, setConfirmok] = useState(false);

  const algodToken = "";
  const algodServer = "https://node.algoexplorerapi.io/";
  const algodPort = "";

  const algodClient = new algosdk.Algodv2(
    "",
    "https://node.testnet.algoexplorerapi.io/",
    ""
  );
  // console.log({ formData });

  let navigate = useNavigate();

  const displayComponent = () => {
    if (page === 0) {
      return <FormPartOne formData={formData} setFormData={setFormData} />;
    } else if (page === 1) {
      return (
        <FormPartTwo
          attribute={attribute}
          setAttribute={setAttribute}
          formData={formData}
          setFormData={setFormData}
        />
      );
    } else if (page === 2) {
      return <FormPartThree formData={formData} setFormData={setFormData} />;
    } else if (page === 3) {
      return (
        <FormPartFour
          accounts={accounts}
          connectWallet={connectWallet}
          formData={formData}
          setFormData={setFormData}
        />
      );
    } else if (page === 4) {
      return <FormPreview attribute={attribute} formData={formData} />;
    }
  };

  const myAlgoConnect = new MyAlgoConnect({ disableLedgerNano: false });

  const settings = {
    shouldSelectOneAccount: true,
    openManager: true,
  };

  const connectWallet = async (wallet = "pera" || "algo") => {
    if (wallet == "pera") {
      delete window?.algorand;
      reach = loadStdlib({ REACH_NO_WARN: "Y" });
      reach.setWalletFallback(
        reach.walletFallback({
          // providerEnv: "MainNet",
          providerEnv: networkConfig.network,
          WalletConnect: MakePeraConnect(PeraWalletConnect),
        })
      );
    } else {
      delete window?.algorand;
    }
    console.log("connection");
    const account = await reach.getDefaultAccount();
    console.log({ account });
    setWallet(account);
    setBalanceMsg("");
    setAccounts([
      {
        address: account.networkAccount.addr,
        name: account.networkAccount.addr,
      },
    ]);

    // setBalanceMsg("");
    // setAccounts();
    setParams();
  };

  const setParams = () => {
    algodClient
      .getTransactionParams()
      .do()
      .then((d) => {
        setParam(d);
        // console.log('params: ', d)
        setFormData({
          ...formData,
          suggestedParams: d,
        });
        setBalanceMsg("");
      })
      .catch((e) => {
        setErrorOccured(true);
        console.error(e);
      });
  };

  const uploadFile = async (file) => {
    let formData = new FormData();
    formData.append("file", file);

    const resFile = await axios({
      method: "post",
      url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
      data: formData,
      headers: {
        pinata_api_key: pinataApiKey,
        pinata_secret_api_key: pinataApiSecret,
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(resFile.data);
    if (resFile.data.status > 201) {
      setLoading(false);
      return false;
    }
    const ImgHash = `${resFile.data.IpfsHash}`;
    return ImgHash;
  };

  const uploadJson = async (obj) => {
    const str = JSON.stringify(obj);
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
      type: "application/json;charset=utf-8",
    });
    const jsonHash = await uploadFile(blob);
    const jsonUrl = `ipfs://${jsonHash}`;
    return jsonHash;
  };

  const handleCreateNFT = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (localStorage.getItem("role") !== "Approved") {
      setErrorMsg("Only registered organisation can create NFT.");
      setLoading(false);
      return;
    }

    const imageHash = await uploadFile(formData.file);
    if (imageHash !== false) {
      setErrorOccured(true);
      return;
    }
    const assetUrl = `https://ipfs.io/ipfs/${imageHash}`;
    setAssetUrl(`https://ipfs.io/ipfs/${imageHash}`);

    const obj = {
      name: `${formData.assetName}@arc69`,
      description: `${formData.description}`,
      media_url: assetUrl,
      mime_type: `${formData?.file?.type}`,
      standard: "ARC69",
      properties: {
        //   issuing_organisation: `${formData.issuingOrganisation}`,
        //   project_name: `${formData.project.project_name}`,
        certificate_owner: `${localStorage.getItem("org_name")}`,
        "CO2 credits": `${formData.co2Offset}`,
        unit_offset: `${formData.unitName}`,
        vintage: `${formData.vintage}`,
      },
    };

    let strObj = JSON.stringify(obj);

    await ARC_69(wallet, {
      name: `${formData.assetName}@arc69`,
      image_url: assetUrl,
      symbol: formData.unitName,
      decimals: +formData.decimals,
      supply: +formData.total,
      metadata: obj,
    }).catch((error) => {
      setErrorMsg(
        `Unfortunately your transaction failed to confirm. Please try again.`
      );
      console.log(error);
      setLoading(false);
      setErrorOccured(true);
      return;
    });

    setMessage("Successfully Minted NFT");

    let newFormData = new FormData();
    newFormData.append("file", formData?.file);
    newFormData.append("certificate_owner", formData?.certificateOwner);
    newFormData.append(
      "certificate_owner_email",
      formData?.certificateOwnerEmail
    );
    newFormData.append("description", formData?.description);
    newFormData.append("address", formData?.address);
    newFormData.append("hash", imageHash);
    newFormData.append("asset_url", assetUrl);
    newFormData.append("asset_name", formData?.assetName);
    newFormData.append("unit_name", formData?.unitName);
    newFormData.append("carbon_credits", formData?.co2Offset);
    newFormData.append("issuing_organisation", formData?.issuingOrganisation);
    // newFormData.append("tx_id", transactionID);
    newFormData.append(
      "sale_proceed_percentage",
      formData?.saleProceedPercentage
    );
    newFormData.append("asset_metadata", strObj);
    newFormData.append("organisation", formData?.project?.organisation);
    newFormData.append("project", formData?.project?.id);
    newFormData.append("name", formData?.project?.category);
    newFormData.append("file", `https://ipfs/${imageHash}`);
    newFormData.append("file_mimetype", formData?.file?.type);

    // use metadata, issuing_organisation, co2_offset, unit_name, certificate_owner, unit_name,
    // description, certificate_expiry=date_of_certificate_expiration from above

    // if (errorOccured === false) {
    //   await axios
    //     .post(`${API_URL}/nfts/all/`, newFormData, CONFIG)
    //     .then((res) => {
    //       console.log("res:", res);
    //       if (res.status === 201) {
    //         console.log("Done");
    //         swal({
    //           title: "Success",
    //           text: `Congratulations! Your NFT has been created successfully. Your transaction was confirmed on Algorand.`,
    //           icon: "info",
    //           button: false,
    //           timer: 2000,
    //         });
    //         navigate(`/nfts/${res.data.nft.id}`);
    //       } else {
    //         swal({
    //           title: "Failed",
    //           text: "Failed to create NFT",
    //           icon: "error",
    //           button: false,
    //           timer: 1300,
    //         });
    //       }
    //       setLoading(false);
    //     })
    //     .catch((error) => {
    //       setLoading(false);
    //       console.log(error.message);
    //     });
    // } else {
    //   swal({
    //     title: "Failed",
    //     text: "Failed to create NFT",
    //     icon: "error",
    //     button: false,
    //     timer: 1300,
    //   });
    // }
    setLoading(false);
    console.log("Confirming");
    // await confirmTransaction();
  };
  const [timeLineState, setTimeLineState] = useState({
    value: page,
    previous: 0,
  });
  const values = [1, 2, 3, 4, 5];
  useEffect(() => {
    setTimeLineState({
      ...timeLineState,
      value: page,
    });
  }, [page]);

  const checkBalance = async () => {
    setLoading(true);
    if (formData.address === null || formData.address.length < 1) {
      setLoading(false);
      setBalanceMsg(
        "You have not yet confirmed your wallet. Please select your wallet first!"
      );
      setErrorOccured(true);
      return;
    }
    var addrr = formData.address;
    var corraddr = JSON.stringify(addrr);
    const bal = await wallet.balanceOf();
    const amount = BNtoN(bal);

    // let accountInfo = await algodClient.accountInformation(addrr).do();

    if (amount <= 200000) {
      setBalanceMsg(
        `Your wallet has ${amount} microAlgos, which is not sufficient to create an NFT. Please buy Algos and try again!`
      );
      setLoading(false);
      setErrorOccured(true);
      return;
    } else {
      setBalanceMsg(`Your wallet has ${amount} microAlgos.`);
    }
    setLoading(false);
    setErrorOccured(false);
    setCheckok(true);
  };

  const handleSendNFT = async () => {
    setLoading(true);
    if (formData.file === null || formData.file.length < 1) {
      setLoading(false);
      setNftMsg(
        "You have not selected an NFT certificate. Please choose a certificate first!"
      );
      setErrorOccured(true);
      return;
    }
    const imageHash = await uploadFile(formData.file);
    setImageHashCode(imageHash);
    setAssetUrl(`https://ipfs.io/ipfs/${imageHash}`);
    const imageAsset = `https://ipfs.io/ipfs/${imageHash}`;

    const obj = {
      name: `${formData.assetName}@arc69`,
      description: `${formData.description}`,
      media_url: imageAsset,
      mime_type: `${formData?.file?.type}`,
      standard: "ARC69",
      properties: {
        issuing_organisation: `${localStorage.getItem("org_name")}`,
        CO2_credits: `${formData.co2Offset}`,
        unit_credit: `${formData.unitName}`,
      },
    };

    let strObj = JSON.stringify({ ...obj, asset_url: imageAsset });
    setStringObj(strObj);
    // // let sha256code = CryptoJS.SHA256(strObj);

    // let buffer = Buffer.from(sha256code.toString(CryptoJS.enc.Hex), "hex");
    // let mdata = new Uint8Array(buffer);

    // const defaultManager =
    //   "UMLE6PX357QBQTV5WGW2IJ2HX5QG5GYMSORYV72TCFCDPIABY5VPTCWQTM";
    // const defaultFreeze = undefined;
    // const defaultClawback = undefined;
    // const defaultReserve = undefined;

    // const txn = algosdk.makeAssetCreateTxnWithSuggestedParamsFromObject({
    //   from: formData.address,
    //   defaultFrozen: true,
    //   manager: defaultManager,
    //   freeze: defaultFreeze,
    //   clawback: defaultClawback,
    //   reserve: defaultReserve,
    //   unitName: formData.unitName,
    //   assetURL: imageAsset,
    //   assetName: `${formData.assetName}@arc69`,
    //   total: +formData.total,
    //   type: "acfg",
    //   total: 1,
    //   decimals: +formData.decimals,
    //   suggestedParams: { ...formData.suggestedParams },
    //   assetMetadataHash: mdata,
    // });

    const tok = await ARC_69(wallet, {
      name: `${formData.assetName}@arc69`,
      image_url: imageAsset,
      symbol: formData.unitName,
      decimals: +formData.decimals,
      supply: +formData.total,
      metadata: obj,
    }).catch((err) => {
      console.error(err);
      setErrorMsg(
        `Unfortunately your transaction failed to confirm. Please try again.`
      );
      setLoading(false);
      setErrorOccured(true);
    });
    console.log({ tok });

    // const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());
    // console.log("signedTxn: ", signedTxn);

    // const response = await algodClient.sendRawTransaction(signedTxn.blob).do();
    // console.log("response: ", response, typeof response);

    // let assetID = null;
    // // wait for transaction to be confirmed
    // const ptx = await algosdk.waitForConfirmation(
    //   algodClient,
    //   response.txId,
    //   4
    // );
    // // Get the new asset's information from the creator account
    // assetID = ptx["asset-index"];
    setFormData({
      ...formData,
      assetId: tok,
    });

    setNftMsg("Your NFT creation transaction is being sent to the blockchain.");
    setTrxId(12345);
    setLoading(false);
    setNftok(true);
    // await confirmTransaction();
  };

  const confirmTransaction = async () => {
    try {
      setLoading(true);
      // let confirmedTxn = await algosdk.waitForConfirmation(algodClient, trxId, 4);
      // setMessage(
      //   "Transaction " +
      //     trxId +
      //     " confirmed in round " +
      //     confirmedTxn["confirmed-round"],
      //   typeof confirmedTxn["confirmed-round"]
      // );

      // if (
      //   confirmedTxn["confirmed-round"] <= 0 ||
      //   typeof confirmedTxn["confirmed-round"] != "number"
      // ) {
      //   setErrorMsg(
      //     `Unfortunately your transaction failed to confirm. Please try again.`
      //   );
      // setLoading(false);
      // setMessage(null);
      // return;
      // } else {
      setMessage("You have successfully Minted");
      // }
      console.log(assetUrl);
      let newFormData = new FormData();
      newFormData.append("file", formData?.file);
      newFormData.append("certificate_owner", formData?.certificateOwner);
      newFormData.append(
        "certificate_owner_email",
        formData?.certificateOwnerEmail
      );
      newFormData.append("description", formData?.description);
      newFormData.append("address", formData?.address);
      newFormData.append(
        "manager",
        "MFGXPLADXYUKOXWYHL2IKN6PEFIW75OXS6MO7VE22Q4EG6JVWE3DRRRBD4"
      );
      newFormData.append("clawback", formData?.address);
      newFormData.append("freeze", formData?.address);
      newFormData.append("hash", imageHashCode);
      newFormData.append("asset_url", assetUrl);
      newFormData.append("asset_name", formData?.assetName);
      newFormData.append("unit_name", formData?.unitName);
      newFormData.append("carbon_credits", formData?.co2Offset);
      // newFormData.append("vintage", formData?.vintage);
      newFormData.append("tx_id", trxId);
      newFormData.append("asset_metadata", stringObj);
      newFormData.append("organisation", localStorage.getItem("org_id"));
      newFormData.append("file_url", `https://ipfs/${imageHashCode}`);
      newFormData.append("file_mimetype", formData?.file?.type);
      newFormData.append("total", formData?.total);
      newFormData.append("decimals", formData?.decimals);
      newFormData.append("asset_id", formData?.assetId);

      if (errorOccured === false) {
        await axios
          .post(`${API_URL}/nfts/all/`, newFormData, CONFIG)
          .then((res) => {
            console.log("res:", res);
            if (res.status === 201) {
              swal({
                title: "Success",
                text: `Congratulations! Your NFT has been created successfully. Your transaction was confirmed  on Algorand.`,
                icon: "info",
                button: false,
                timer: 2000,
              });
              navigate(`/my-nfts`);
            } else {
              swal({
                title: "Failed",
                text: "Failed to create NFT",
                icon: "error",
                button: false,
                timer: 1300,
              });
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error.message);
          });
      }
      setLoading(false);
    } catch (e) {
      console.error({ e });
    }
  };

  return (
    <div className="bg page">
      <div className="container px-4 px-lg-0 px-md-0 px-sm-0">
        {
          <div className="row my-4">
            <div className="timeline col-12 col-lg-3 col-md-12 d-none d-lg-block">
              <Timeline>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color="dark" />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>Upload file</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color={page >= 1 ? "dark" : "primary"} />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>Description</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color={page >= 2 ? "dark" : "primary"} />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>NFT information</TimelineContent>
                </TimelineItem>

                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color={page >= 3 ? "dark" : "primary"} />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>Connect wallet</TimelineContent>
                </TimelineItem>

                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color={page === 4 ? "dark" : "primary"} />
                  </TimelineSeparator>
                  <TimelineContent>Review & Submit</TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
            {/* <p>{ formData.file }</p> */}

            <div
              className={`view col-12 col-lg-4 col-md-12 d-lg-block ${
                formData.file ? "d-md-block" : "d-md-none"
              }`}
            >
              {formData.fileType.includes("image") && (
                <img
                  src={formData.fileSrc}
                  alt="form data"
                  width="100%"
                  height="400px"
                />
              )}
              {formData.fileType.includes("pdf") && (
                <iframe
                  src={formData.fileSrc}
                  width="100%"
                  height="400px"
                ></iframe>
              )}

              {/* <Document file={formData.file}>
                                <Page pageNumber={1} />
                            </Document> */}
            </div>
            <div className="col-12 col-lg-5 col-md-12">
              <div className="d-lg-none">
                <div style={{ width: "100%", height: "60px" }}>
                  <HorizontalTimeline
                    index={timeLineState.value}
                    labelWidth={250}
                    isTouchEnabled={true}
                    getLabel={(data) => {
                      if (data === 1) {
                        return "Upload file";
                      }
                      if (data === 2) {
                        return "Description";
                      }
                      if (data === 3) {
                        return "Connect wallet";
                      }
                      if (data === 4) {
                        return "NFT information";
                      }
                      if (data === 5) {
                        return "Review & Submit";
                      }
                    }}
                    indexClick={(index) => {
                      setTimeLineState({
                        value: index,
                        previous: timeLineState.value,
                      });
                    }}
                    {...values}
                    values={values}
                  />
                </div>
              </div>
              <Form>
                <ModalBody>
                  <div>{displayComponent()}</div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between">
                  <ReactButton
                    className={page === 0 ? "d-none back-btn" : "back-btn"}
                    color="warning"
                    outline
                    disabled={page === 0}
                    onClick={() => {
                      setPage((currPage) => currPage - 1);
                      if (page <= 3) {
                        setCheckok(false);
                        setNftok(false);
                        setConfirmok(false);
                      }
                    }}
                  >
                    Go Back to Edit
                  </ReactButton>

                  <div className="action">
                    {page !== 4 && (
                      <ReactButton
                        color="d-block"
                        outline
                        type="button"
                        disabled={Boolean(
                          (formData.unitName.length > 8 && page >= 3) ||
                            localStorage.getItem("role") !== "W-Approved"
                        )}
                        className={`next-btn ${
                          formData.unitName.length > 8 && page >= 3
                            ? "d-none"
                            : ""
                        }`}
                        onClick={() => {
                          setPage((currPage) => currPage + 1);
                        }}
                      >
                        <span>Next</span>
                      </ReactButton>
                    )}
                    {page === 4 && (
                      <>
                        <div className="d-flex flex-row justify-content-between">
                          <ReactButton
                            color="ml-2"
                            outline
                            type="button"
                            disabled={Boolean(
                              (formData.unitName.length > 8 && page >= 3) ||
                                localStorage.getItem("role") !== "W-Approved"
                            )}
                            className={`next-btn ${
                              (formData.unitName.length > 8 && page >= 3) ||
                              checkok
                                ? "d-none"
                                : ""
                            }`}
                            onClick={checkBalance}
                          >
                            {loading === true && (
                              <span className="d-block">
                                <Spinner size="sm">Loading...</Spinner>
                              </span>
                            )}
                            <span> Check Balance</span>
                          </ReactButton>
                          <p className="ml-3 pl-2">{balanceMsg}</p>
                        </div>
                        <div className="">
                          <ReactButton
                            color="my-2 ml-2"
                            outline
                            type="button"
                            disabled={Boolean(
                              (formData.unitName.length > 8 && page >= 3) ||
                                localStorage.getItem("role") !== "W-Approved" ||
                                errorOccured === true
                            )}
                            className={`back-btn ${
                              (formData.unitName.length > 8 && page >= 3) ||
                              !checkok ||
                              nftok
                                ? "d-none"
                                : ""
                            }`}
                            onClick={handleSendNFT}
                          >
                            {loading === true && (
                              <span className="d-block">
                                <Spinner size="sm">Loading...</Spinner>
                              </span>
                            )}
                            <span> Create NFT</span>
                          </ReactButton>
                          <p className="ml-3 pl-2">{nftMsg}</p>
                        </div>
                        <div className="">
                          <ReactButton
                            color="ml-2"
                            outline
                            type="button"
                            disabled={Boolean(
                              (formData.unitName.length > 8 && page >= 3) ||
                                localStorage.getItem("role") !== "W-Approved" ||
                                (errorOccured === true && nftMsg)
                            )}
                            className={`back-btn ${
                              (formData.unitName.length > 8 && page >= 3) ||
                              !checkok ||
                              !nftok
                                ? "d-none"
                                : ""
                            }`}
                            onClick={confirmTransaction}
                          >
                            {loading === true && (
                              <span className="d-block">
                                <Spinner size="sm">Loading...</Spinner>
                              </span>
                            )}
                            <span> Confirm Transaction</span>
                          </ReactButton>
                          {/* <p className='ml-3 pl-2'>{ nftMsg }</p> */}
                        </div>
                      </>
                    )}
                    {localStorage.getItem("role") === "W-Approved" ? (
                      ""
                    ) : (
                      <p className="aler-msg">
                        You should be a registered organisation to use this
                        feature.
                      </p>
                    )}
                  </div>
                </ModalFooter>
                {errorMsg ? <p className="text-danger">{errorMsg}</p> : ""}
                {message && !errorMsg ? (
                  <p className="text-primary" style={{ color: "#212529" }}>
                    {" "}
                    {message}{" "}
                  </p>
                ) : (
                  ""
                )}

                {/* { localStorage.getItem('role')  === "Approved" && page === 0 ?
                                    <div className='mt-3'>
                                        <Typography className='d-block guides' gutterBottom variant="h7" color="black" justifycontent={"left"}>How to create an NFT through CNet0?</Typography>
                                        <div className="video-container guides">
                                            <iframe className='video' src="" title="Create an NFT" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </div>
                                    </div> : ('')
                                } */}
              </Form>
            </div>
            <div className="col-1"></div>
          </div>
        }
      </div>
    </div>
  );
};

export default NftForm;
