import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
// import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
// import Co2Icon from '@mui/icons-material/Co2';
// import { Row, Col } from 'react-bootstrap';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import swal from 'sweetalert';
import PathImage from '../Images/path1.jpeg';
import DiamondIcon from '@mui/icons-material/Diamond';
import Top from '../components/Top/Top'
import '../App.css'
import './home.css'


function Home() {

    const navigate = useNavigate();

    useEffect(() => {
        const carbon_offset = localStorage.getItem("carbon_offset");
        if (window.location.search === '?success=true') {
            swal({
                title: "Offset your emissions",
                text: "You have successfully paid for offsetting your emissions",
                icon: "success",
                button: false,
                timer: 1500,
            });
            setTimeout(() => {
                navigate(`/`);
            }, 300)
        }
        localStorage.removeItem("carbon_offset");
    }, [])


    return (
        <div className='home-page' style={{ backgroundColor: '#E3BC9A21'}}>
            <Top />
            <div className="container px-4 px-lg-0 px-md-0 px-sm-0 partner">
                <div className="row mt-4" style={{ justifyContent: 'center'}}>
                        <div className="col-md-6">
                            <h6 style={{ textAlign: 'left'}}>DCarbonX</h6>
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/rPFefbatyxk" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <br/>
                        </div>
                        <div className="col-md-6">
                            <h6 style={{ textAlign: 'left'}}>Know about climate change & DCarbonX</h6>
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/bBDFC42eq1s"  frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="jumbotron d-none" id="knowledge">
                        <div className="col">
                        {/* <h4 className='text-center'>  <i className="fa fa-diamond" aria-hidden="true"></i>  Salient Features</h4> */}
                        <Typography color="text.secondary" style={{ textAlign: 'center'}}>
                            <DiamondIcon sx={{ alignSelf: 'center', color: "#379AE5", fontSize: 60 }} />
                            <br />
                            <b>Salient Features</b>
                        </Typography>
                        <br/>
                        <ul className="list-unstyled" style={{ textAlign: 'center'}}>
                            <li><i className="fa fa-check"></i> Interface to view all issued carbon credit/ offset certificates</li>
                            <li><i className="fa fa-check"></i>  Primary and secondary market for trading of carbon credits</li>
                            <li><i className="fa fa-check"></i>  Permanent digital record of issued certificates on the blockchain</li>
                            <li><i className="fa fa-check"></i>  Primary market listing controlled by the organisation</li>
                            <li><i className="fa fa-check"></i>  Helps to combat greenwashing</li>
                        </ul>
                    </div>
                </div>
            </div>


            <div className="py-5 rounded-lg"  style={{ backgroundColor: '#E3BC9A0D'}}>
                <div className='container px-4 px-lg-0 px-md-0 px-sm-0' >
                    <div className='row mt-2'>
                        <div className='col-12 col-md-6 pt-0'>
                            <Typography color="text.secondary" style={{ textAlign: 'center'}}>
                                <DiamondIcon sx={{ alignSelf: 'center', color: "#379AE5", fontSize: 60, marginTop: '20px' }} />
                                <br />
                                <b>Salient Features</b>
                            </Typography>
                        </div>
                        <div className='col-12 col-md-6' sm={(12)} style={{ disply: 'flex', justifycontent: 'center' }}>
                            <ul className="list-unstyled">
                            <li><i className="fa fa-check"></i> Interface to view all issued carbon credit certificates</li>
                            <li><i className="fa fa-check"></i>  Primary and secondary market for trading of carbon credits</li>
                            <li><i className="fa fa-check"></i>  Permanent digital record of issued certificates on the blockchain</li>
                            <li><i className="fa fa-check"></i>  Primary market listing controlled by the organisation</li>
                            <li><i className="fa fa-check"></i>  Helps to combat greenwashing</li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-5 px-4"  style={{ backgroundColor: '#E3BC9A0D'}}>
                <div className='' >
                    <Typography gutterBottom variant="h5" component="div" align='center'>
                        Path to attaining net carbon neutrality
                    </Typography>
                    <div className='row'>
                        <div className="container">
                            <Typography color="text.secondary" justifycontent={"left"} sx={{ mt: 2, p: 2 }}>COP21 was a landmark event in 
                            the fight against climate change as all 196 countries at the UN signed the international treaty on climate 
                            change mitigation, commonly known as Paris accord. The primary aim is to keep the rise in mean global temperature 
                            to below 2° C and preferably to 1.5° C. In order to achieve this target, the pathway defined in COP26 was to 
                            aim for 50-55% reduction in GtCO2eq (vs 2010 base) and  <b>Net Zero</b> emissions by 2050.</Typography>
                        </div>
                        <p></p>
                        <p></p>
                        <img src={PathImage} style={{ width: '100%', backgroundColor: 'white'}}></img>

                        <div className='pt-4 mb-3' style={{ width: '100%', backgroundColor: 'white'}}>
                            <Typography fontSize={"24px"} color="black" textAlign={"center"} fontWeight={"bold"}>CO2 credit database record (GtCO2eq) =&gt; CO2 reduction (vs 2010 ref) + net carbon negative projects' impact</Typography>
                        </div>

                        <Typography color="text.secondary" justifycontent={"left"}>The general agreement among the climate experts and the business community is that the pathway to <b>Net Zero</b> will have 2 phases:</Typography>
                        <ul className="list-unstyled" justifycontent-={'left'}>
                            <li><WbSunnyIcon sx={{ alignSelf: 'center'}} /> <b>Upto 2030:</b> <span>When incremental improvement using current technologies will lead to moderate reduction in CO2 emissions but that will not be enough. In this case carbon offsetting through <b>net carbon negative projects</b> will play a key role in reaching the 50-55% emission reduction target.</span></li>
                            <li><WbSunnyIcon sx={{ alignSelf: 'center' }} /> <b>From 2030-50:</b> <span>When breakthrough technologies like carbon capture and storage, as well as net zero industrial products will reach required scale and will be economical for mass adoption in the private sector. This will drive the net zero pathway.</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Home