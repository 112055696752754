import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';
// import swal from 'sweetalert';

import { API_URL } from '../../utils'
import './org_detail.css'

const OrganisationDetail = () => {
    let { id } = useParams()

    const [organisation, setOrganisation] = useState({})

    useEffect(() => {
        axios.get(`${API_URL}/organisations/${id}/`)
            .then(res => {
                // console.log('org:', res.data);
                setOrganisation(res.data);
            })
            .catch((error) => {
                console.log(error.message);
            })
    }, [])


    return (
        <div className="py-4 py-md-5 bg page">
            <div className='container px-4 px-lg-0 px-md-0 px-sm-0' >
                <div className="row pb-3">
                    <div className="col-12 col-md-12 col-lg-4  col-xs-12 pb-md-4  pb-lg-0 pb-0">
                        <div className='bg-white h-100 w-100 d-flex flex-column flex-sm-row justify-content-start align-items-start align-items-md-center gap-2 py-4 px-4 px-sm-3 rounded justify-content-md-center   align-items-start align-items-md-center'>
                            <div>
                                <img style={{ width: '80px', height: '70px' }} src={organisation.logo} alt="Thumbnail" />
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-start'>
                                <h5 className='title mb-0'>Name</h5>
                                <p className='value mb-0'>{organisation.organisation_name}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-12 col-lg-4 col-sm-12 col-xs-12 pb-md-4 pb-lg-0 pb-0">
                        <div className='bg-white h-100 w-100 d-flex justify-content-start justify-content-md-center   align-items-start align-items-md-center flex-column py-0 py-sm-4 bg-light px-4'>
                            <h5 className='title'>Country</h5>
                            <p className='value'>{organisation.organisation_country}</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-4 col-sm-12 col-xs-12 pb-md-4 pb-sm-4 pb-xs-4 pb-lg-0 pb-4">
                        <div className='bg-white h-100 w-100 d-flex justify-content-start align-items-start flex-column py-4 bg-light px-4 justify-content-md-center   align-items-start align-items-md-center'>
                            <h5 className='title'>Registration identifier</h5>
                            {
                                organisation.relevant_registration ? <p className="value">{organisation.relevant_registration}</p>
                                    : ('')
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <div className='line_org d-none d-md-block'>

                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-md-12 title">
                        <h5>Registration identifier</h5>
                    </div>
                    <div className="value col-12 py-1">
                        {
                            organisation.relevant_registration ? <p>{organisation.relevant_regulatory_ref}</p>
                                : ('')
                        }
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-md-12 title">
                        <h5> Description</h5>
                    </div>
                    <div className="value col-12 py-1">
                        {
                            organisation.organisation_description ? <p>{organisation.organisation_description}</p>
                                : ('')
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrganisationDetail
